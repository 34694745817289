import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Highlight from "react-highlight.js"

import "../../styles/blog.css"
import "../../styles/atom-one-dark-reasonable.css"

export default function RailsStrongParametersShortcutForScopingCurrentUserToRecord() {
  return (
    <Layout>
      <SEO title="Rendering a Partial Outside of a View or Controller in Rails 6 | Primespot Blog" />
      <main>
        <h1>
          Rails Strong Parameters Shortcut for Scoping current_user to Record
        </h1>
        <p className="text-gray-600 mt-2 mb-4" style={{ fontSize: "16px" }}>
          Primespot Engineering - June 06, 2020
        </p>
        <div className="mb-4">
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-teal-100 text-teal-800">
            Engineering
          </span>
        </div>
        <p>
          This one kind of blew my mind. To be honest, I’m not quite sure why I
          never thought of it. I got this tip from{" "}
          <a href="https://gorails.com">https://gorails.com</a> while watching
          the episode titled{" "}
          <em>Realtime Group Chat with Rails [Revised] - Part 2.</em>
        </p>
        <p>
          In this episode, Chris Oliver (founder of gorails.com) showed his
          method for scoping the current_user to a record in a controller.
        </p>
        <p>
          First of all, this tip is pure Ruby. Before I get into it, let me show
          you what I’ve been doing so far in my Rails controllers.
        </p>
        <Highlight language="rails">
          {`def create
  @record = Record.new record_params
  @record.user = current_user
  ...
end`}
        </Highlight>
        <p>
          This works great. And in some situations, it might be better than the
          shortcut I’m going to show you. It’s definitely a bit more flexible
          than the shortcut.
        </p>
        <p>
          This shortcut takes advantage of the Ruby Hash.merge method. This
          method merges a hash into another hash. It’s sort of like JavaScript’s
          Object.assign() function.
        </p>
        <Highlight language="rails">
          {`private

  def record_params
    params.require(:data).permit(:value, :another).merge(user: current_user)
  end`}
        </Highlight>
        <p>
          By chaining the merge method onto the params.require.permit chain,
          we’re able to add the current_user to the return value of
          record_params. This will assign the correct user value to the newly
          created record in one line instead of two.
        </p>
        <p>This:</p>
        <Highlight language="rails">
          {`def create
  @record = Record.new record_params
  @record.user = current_user
  ...
end`}
        </Highlight>
        <p>Becomes:</p>
        <Highlight language="rails">
          {`def create
  @record = Record.new record_params
  ...
end`}
        </Highlight>
        <p>
          I won’t use this approach in all situations. Sometimes I will prefer
          the flexibility of handling the scoping directly. But I will probably
          take this approach at least part of the time.
        </p>
      </main>
    </Layout>
  )
}
